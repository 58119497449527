// Event Schedule
.event-schedule-title {
  @include font-size($base-font-size);
  @include line-height($base-line-height);
  position: relative;
  background-color: $light-grey;
  padding: 10px 30px 10px 10px;
  border-radius: 0 5px 0 0;
  &:hover { text-decoration: none; }
  &:after {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -8px;
    background-image: url(../img/circle-with-minus.svg); // $teal
  }
  &.collapsed {
    border-radius: 0 5px 5px 5px;
    &:after {
      background-image: url(../img/circle-with-plus.svg); //teal
    }
  }
}
.event-schedule-time {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  color: $white;
  background-color: $copy-grey;
  border-radius: 5px;
  padding: 5px 10px;
}
.event-schedule-name {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  color: $copy-grey;
}
