// Branding
.logo {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  & > svg {
    display: inline-block;
    max-width: 100%;
    -webkit-filter: drop-shadow( 0px 0px 10px #fff );
            filter: drop-shadow( 0px 0px 10px #fff );
  }
  &.active {
    .fill {
      fill: $black; //rgb(0,57,107);
      transition: all .5s;
    }
  }
}

// Banner
.banner {
  background: url(../img/steemfest2019bg.jpg) 50% 50% no-repeat;
  background-size: cover;
  color: $white;
  // background-attachment: fixed;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;


}
.banner-heading {
  @extend .h-white;
  text-transform: uppercase;
  font-weight: 700;
  margin: .7em 0;
  color: $white;
  -webkit-filter: drop-shadow( 0px 0px 5px $bluesteem2 );
          filter: drop-shadow( 0px 0px 5px $bluesteem2 );
  text-shadow: 0px 0px 2px $bluesteem2;
  display: inline-block;
  background-color: $bluesteem2;
  padding: 5px 10px;
}
.banner-buttons {
  margin-top: 40px;
  .button {
    display: block;
    margin-top: 10px;
    @include breakpoint(m) {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.blue.button { -webkit-animation-name: bluePulse; -webkit-animation-duration: 6s; }

a.button {
		-webkit-animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
	}

@-webkit-keyframes bluePulse {
  from { background-color: $bluebaby; -webkit-box-shadow: 0 0 9px #333; color: $white; }
  50% { background-color: $grey; -webkit-box-shadow: 0 0 18px $lighter-grey; color: $bluesteem2;}
  to { background-color: $bluebaby; -webkit-box-shadow: 0 0 9px #333; color: $white;}
}
