// Event Speakers
.event-speakers {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
.event-speaker {
  width: 140px;
  height: 140px;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: all .2s;
  background-color: $grey;
  border-radius: 100%;
  position: relative;
  &:before, &:after {
    content: "";
    position: absolute;
    display: block;
  }
  &:before {
    transition: all .2s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    @include background-alpha($bluesteem2, 0%);
    z-index: 10;
  }
  &:after {
    width: 50px;
    height: 50px;
    background-image: url(../img/steemit.svg);
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 20;
    opacity: 0;
  }
  &:hover {
    &:before {
      @include background-alpha($white, 50%);
      border:5px solid $bluesteem2;
    }
    &:after {
      @include animation(.1s);
      @extend .fade-in-up;
    }
  }
}
.event-speaker-name {
  text-decoration: none;
  position: relative;
  transition: all .2s;
  color: $copy-grey;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: $teal;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.1s ease-in-out 0s;
  }
  &:hover {
    color: $teal;
    text-decoration: none;
    &:after {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.width144 {
    width: 144px;
}
