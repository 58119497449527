// Website Variables and Colors
$base-background-color: #fff;
$base-font-family: "RidleyGrotesk-Regular", sans-serif;
$base-font-family-light: "RidleyGrotesk-Light", sans-serif;
$base-font-family-bold: "RidleyGrotesk-Bold", sans-serif;
$base-font-family-black: "RidleyGrotesk-Black", sans-serif;
$base-font-family-thin: "RidleyGrotesk-Thin", sans-serif;
$base-font-family-medium: "RidleyGrotesk-Medium", sans-serif;
$base-font-family-code: "Monaco", "Consolas", "Andale Mono", "Lucida Console", "Courier New", "Courier", sans-serif;
$base-font-size: 14;
$base-line-height: 22;
$base-font-weight: 400;
$base-font-color: #B3206E;
$base-link-color: #05A0B3;
$base-link-hover-color: #FFE62E;

// Headings
$base-heading-font-family: "RidleyGrotesk-Medium", sans-serif;
$base-h1-font-size: 32;
$base-h1-line-height: 38;
$base-h1-font-weight: 400;
$base-h1-color: #FF1493;
$base-h2-font-size: 26;
$base-h2-line-height: 34;
$base-h2-font-weight: 400;
$base-h2-color: #FF1493;
$base-h3-font-size: 22;
$base-h3-line-height: 28;
$base-h3-font-weight: 400;
$base-h3-color: #FF1493;
$base-h4-font-size: 18;
$base-h4-line-height: 24;
$base-h4-font-weight: 400;
$base-h4-color: #FF1493;
$base-h5-font-size: 16;
$base-h5-line-height: 22;
$base-h5-font-weight: 400;
$base-h5-color: #FF1493;
$base-h6-font-size: 14;
$base-h6-line-height: 20;
$base-h6-font-weight: 400;
$base-h6-color: #FF1493;

// Blockquotes
$base-blockquote-font-family: sans-serif;
$base-blockquote-font-size: 22;
$base-blockquote-line-height: 28;
$base-blockquote-color: #424242;
$base-blockquote-cite-color: #777;

// Code
$base-code-font-family: 'courier new', monospace, serif;
$base-code-font-size: 13;
$base-code-line-height: 18;
$base-code-color: #272727;
$base-code-background-color: #f1f1f1;
$base-code-border-color: #d7d7d7;

// Forms
$base-placeholder-color: #828282;
$base-input-placeholder-color: #999;
$base-input-color: #525b64;
$base-input-background-color: #ececec;
$base-input-background-focus-color: #f1f1f1;
$base-input-border-color: transparent;
$base-input-border-focus-color: #2cc7c1;


// Colours
$white: #fff;
$black: #212529;

$orange: #FFE62E;
$green: #FFC857;

$bluebaby: #05A0B3;
$bluesteem: #FF1493;
$bluesteem2: #B3206E;
$bluesteemlight: #BBFF1493;

$red: #f8504d;
$dark-red: #f53a37;
$teal: #2cc7c1;

$smoke: #cacaca;

$copy-grey: #FF1493;

$grey: #1AFF14;
$light-grey: #ccefcc;
$lighter-grey: #e5f7e5;

$dark-blue-grey: #FF1493;
$darker-blue-grey: #B3206E;
