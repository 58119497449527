// Sponsors
.event-sponsor-list {
  list-style: none;
  margin: 0;
  padding: 2em 0;
  li {
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
  }
}

.sponsor {
  height: 40px;
  width: 160px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 20px;
  filter: gray; /* IE6-9 */
   -webkit-transition: all 0.5s;
  -webkit-filter: grayscale(100%) contrast(200%);
   -moz-filter: grayscale(100%) contrast(200%);
     -o-filter: grayscale(100%) contrast(200%);
    -ms-filter: grayscale(100%) contrast(200%);
        filter: grayscale(100%) contrast(200%);

  @include breakpoint(l) {
    margin-bottom: 0;
  }

  &:hover {
    -webkit-filter: none;
     -moz-filter: none;
       -o-filter: none;
      -ms-filter: none;
      filter: none; /* IE6-9 */
  }


}


.sponsor-steemit {
    background-image: url(../img/sponsors/steemit.svg);
}

.sponsor-steem {
    background-image: url(../img/sponsors/steem.svg);
}

.sponsor-steemwallet {
    background-image: url(../img/sponsors/steemwallet.svg);
}

.sponsor-qubus {
    background-image: url(../img/sponsors/qubus.svg);
}

.sponsor-inx {
    background-image: url(../img/sponsors/inx.svg);
}

.sponsor-scorum {
    background-image: url(../img/sponsors/scorum.svg);
}

.sponsor-smooth {
    background-image: url(../img/sponsors/smooth.svg);
}

.sponsor-steemmonsters {
    background-image: url(../img/sponsors/steemmonsters.svg);
}

.sponsor-esteem {
    background-image: url(../img/sponsors/esteem.svg);
}

.sponsor-partiko {
    background-image: url(../img/sponsors/partiko.svg);
}

.sponsor-pharesim {
    background-image: url(../img/sponsors/pharesim.svg);
      width: 70px;
}

.sponsor-rektcoincash {
    background-image: url(../img/sponsors/rektcoincash.svg);
}

.sponsor-bitshares {
    background-image: url(../img/sponsors/bitshares.svg);
}

.sponsor-blocktrades {
    background-image: url(../img/sponsors/blocktrades.svg);
}

.sponsor-pbsa {
    background-image: url(../img/sponsors/pbsa.svg);
}

.sponsor-decent {
    background-image: url(../img/sponsors/decent.svg);
}

.sponsor-skyteam {
    background-image: url(../img/sponsors/travel-skyteam.svg);
}


.sponsor-thaiairways {
    background-image: url(../img/sponsors/travel-thai.svg);
}


.sponsor-lufthansa {
    background-image: url(../img/sponsors/travel-lufthansa.svg);
}

.sponsor-steemengine {
  background-image: url(../img/sponsors/steemengine.svg);
}

.sponsor-splinterlands {
  background-image: url(../img/sponsors/splinterlands.svg);
}

.sponsor-3speak {
  background-image: url(../img/sponsors/3speak.svg);
}


.sponsor-cexio {
  background-image: url(../img/sponsors/media-cexio.svg);
}

.sponsor-coingecko {
  background-image: url(../img/sponsors/media-coingecko.svg);
}

.sponsor-peerplays-grants {
  background-image: url(../img/sponsors/peerplays-grants.svg);
}


// .sponsor-blocktrades {
//   height: 40px;
//   width: 50px;
//   background-image: url(../img/sponsors/blocktrades-grey.svg);
//   &:hover {
//     background-image: url(../img/sponsors/blocktrades.svg);
//   }
// }
