// Shared Components

// Fade to White
@keyframes logoAnimation {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }
  80% {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    fill-opacity: 0;
    transform: scale(1.2);
  }
  100% {
    stroke-dasharray: none;
    stroke-dashoffset: none;
    fill-opacity: 1;
    transform: scale(1);
  }
}
.logo-animation { animation-name: logoAnimation; }


// Spring
@keyframes spring {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.spring { animation-name: spring; }

// Overrides
a {
  text-decoration: none;
  transition: all .2s;
  background: linear-gradient(to bottom, $bluesteem 0%, $bluesteem 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 3px 1px;

  &:hover {
    text-decoration: none;
    background-size: 4px 30px;
  }
  border-radius: 5px;
}

.footer-content-block, .footer-copyright {
  & a {
    background: none;
    color: $light-grey;
    background: linear-gradient(to bottom, $light-grey 0%, $light-grey 100%);
      background-position: 0 100%;
      background-repeat: repeat-x;
      background-size: 3px 1px;

    &:hover {
      text-decoration: none;
      background-size: 4px 50px;
      color: $bluesteem;
    }
  }
}

body {
  font-family: $base-font-family-light;
}

b {
  font-family: $base-font-family-medium;
}

.event-sponsor-list a {
  transition: all .3s;
  background-size: unset;
  &:hover {
    text-decoration: none;
    background-size: unset;
    background-color: transparent;
  }
}

// Custom Buttons
.button {
  border: 0;
  background-color: $dark-blue-grey;
  line-height: normal;
  padding: 12px 26px;
  text-decoration: none;
  color: $white;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  transition: all .2s;
  font-weight: 700;
  overflow: hidden;
  border-radius: 3px;
  font-family: $base-font-family-bold;
  &:hover, &:active {
    text-decoration: none;
    color: $white;
    background-color: $darker-blue-grey;
  }
}
.button-primary {
  background: none;
  background-color: $grey;
  color: $bluesteem2;


  &:hover, &:active { background: none;
    background-color: $bluesteem; }
}

.button-small {
  font-size: 12px;
  padding: 6px 13px;
}

.text-code {
  font-family: $base-font-family-code;
}
h2 {
    font-family: "RidleyGrotesk-Bold", sans-serif;
}

.button-orange {
  background-color: $orange;
  border: 1px solid $white;
  border-radius: 50px;
  color: $bluesteem2;
  &:hover, &:active { background-color: $bluesteem; color: $white; border-color: $bluesteem;}
}


.button-register {
  height: 36px;
  padding: 10px 26px;
}

// Button Styled as Link
.button-link {
  color: $base-link-color;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover { text-decoration: none; }
  &:active { outline: 0; }
}



// Headings
.h-white { color: $white; }
.h-grey { color: $copy-grey; }
.h2 { margin-bottom: .2em; }

// Forms
input.field {
  border-radius: 5px;
  padding: 12px 10px;
}
.select {
  select {
    background: $light-grey;
  }
}

// Icons
.icon {
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    display: block;
    position: absolute;
    transition: all .2s;
    top: 50%;
    margin-top: -8px;
  }
}
.icon-left {
  padding-left: 24px;
  &:before { left: 0; }
}
.icon-right {
  padding-right: 24px;
  &:before { right: 0; }
}

// Chevron Icons
.chevron-right:before { background-image: url(../img/chevron-circle-right.svg); }
.arrow-right:before { background-image: url(../img/arrow-circle-right-teal.svg); }
.arrow-down:before { background-image: url(../img/arrow-circle-down.svg); }

// Contact Icons
.icon-phone:before { background-image: url(../img/phone.svg); }
.icon-email:before { background-image: url(../img/email.svg); }
.icon-skype:before { background-image: url(../img/skype.svg); }
.icon-rocketchat:before { background-image: url(../img/rocketchat.svg); }
.icon-telegram:before { background-image: url(../img/telegram.svg); }

// Tabs
.tabs {
  margin-top: 3em;
  @include breakpoint(l) {
    margin-top: 0;
  }
}
.tabs-primary {
  border: 2px solid $light-grey;
  border-radius: 5px;
}
.tabs-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tab {
  display: block;
  text-transform: uppercase;
  margin: 0;
  @include breakpoint(m) {
    display: inline-block;
  }
  & > a {
    display: block;
    transition: all .2s;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: $dark-blue-grey;
    background-color: $light-grey;
    &:hover {
      background-color: $lighter-grey;
      text-decoration: none;
      color: $black;
    }
  }
  &.active {
    & > a {
      background-color: $white;
    }
  }
}
.tab-content {
  padding: 30px 15px;
}
.tab-pane {
  display: none;
  &.active {
    display: block;
    @include animation(.3s);
    @extend .fade-in;
  }
}

// Tab Secondary (Overrides)
.tab-secondary {
  display: inline-block;
  & > a {
    color: $dark-blue-grey;
    background: none;
    background-color: $white;
    border-radius: 5px 5px 0 0;
    &:hover {
      background-color: $light-grey;
      text-decoration: none;
      color: $black;
    }
  }
  &.active {
    & > a {
      background: none;
      background-color: $lighter-grey;
    }
  }
}
.tab-content-secondary {
  padding: 0;
  border-radius: 0 5px 5px 5px;
}


// Panels
.panel-heading {
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}
.panel-title {
  margin: 0;
  color: $bluesteem2;
  & > a {
    background: none;
    background-color: $lighter-grey;
    display: block;
    padding: 10px;
  }
}
.panel-body {
  padding: 10px;
  background-color: $lighter-grey;
  border-radius: 0 0 5px 5px;
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height, visibility;
}

.color-orange {
  color: $orange;
}

.color-green {
  color: $green;
}

.color-babyblue {
  color: $bluebaby;
}

// Custom Breaks
.hr {
  width: 100%;
  height: 7px;
  border: 0;
  background-color: $lighter-grey;
  margin-top: 0;
  margin-bottom: 0;
}

// Lists
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding-left: 25px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url(../img/check-circle.svg);
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}

// Video
.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Carousel
.carousel { position: relative; }
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  & > .item {
    position: relative;
    display: none;
    & > .image {
      display: block;

    }
    & > img {
      display: block;
      width: 640px;
    }
  }
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    transition: transform .6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.slide-right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.slide-left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.next.slide-left,
  .carousel-inner > .item.prev.slide-right,
  .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active { left: 0; }
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next { left: 100%; }
.carousel-inner > .prev { left: -100%; }
.carousel-inner > .next.slide-left,
.carousel-inner > .prev.slide-right {
  left: 0;
}
.carousel-inner > .active.slide-left { left: -100%; }
.carousel-inner > .active.slide-right { left: 100%; }

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 9000;
  & > li {
    width: 14px;
    height: 14px;
    display: block;
    background-color: transparent;
    display: inline-block;
    border-radius: 100%;
    border: 2px solid $white;
    margin: 0 .2em;
    cursor: pointer;
    &.active {
      background-color: $white;
    }
  }
}

// Content Blocks
.grey-row { background-color: $light-grey; }



     .ticketradiobut {
      width: 80px;
      padding: 5px 5px;
      font-size: 15px;
      line-height: 1.33;
      border-radius: 3px;
      text-align:center;
      border: 2px solid white;
    }

    .ticketradiobut small {
      font-size: 10px;
    }

    .ticketradiobut.active {
      border: 2px solid $bluesteem2;
      color: white;
      background-color: $bluesteem2;
    }


    .tooltip {
      position: absolute;
      z-index: 1070;
      display: block;
      //font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-break: auto;
      line-height: 1.42857143;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      white-space: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      font-size: 12px;
      opacity: 0;
      filter: alpha(opacity=0);
    }
    .tooltip.in {
      opacity: 0.9;
      filter: alpha(opacity=80);
    }
    .tooltip.top {
      margin-top: -3px;
      padding: 5px 0;
    }
    .tooltip.right {
      margin-left: 3px;
      padding: 0 5px;
    }
    .tooltip.bottom {
      margin-top: 3px;
      padding: 5px 0;
    }
    .tooltip.left {
      margin-left: -3px;
      padding: 0 5px;
    }
    .tooltip-inner {
      max-width: 200px;
      padding: 3px 8px;
      color: #ffffff;
      text-align: center;
      background-color: $bluesteem;
      border-radius: 4px;
    }
    .tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
    .tooltip.top .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: $bluesteem;
    }
    .tooltip.top-left .tooltip-arrow {
      bottom: 0;
      right: 5px;
      margin-bottom: -5px;
      border-width: 5px 5px 0;
      border-top-color: $bluesteem;
    }
    .tooltip.top-right .tooltip-arrow {
      bottom: 0;
      left: 5px;
      margin-bottom: -5px;
      border-width: 5px 5px 0;
      border-top-color: $bluesteem;
    }
    .tooltip.right .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
      border-right-color: $bluesteem;
    }
    .tooltip.left .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: $bluesteem;
    }
    .tooltip.bottom .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: $bluesteem;
    }
    .tooltip.bottom-left .tooltip-arrow {
      top: 0;
      right: 5px;
      margin-top: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: $bluesteem;
    }
    .tooltip.bottom-right .tooltip-arrow {
      top: 0;
      left: 5px;
      margin-top: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: $bluesteem;
    }
